import React, { useState } from "react";
import logo from "../images/new.png";
import "../Components/style.css"; // (You can remove custom CSS if fully replaced by Tailwind)
import { Link } from "react-router-dom";
// import PrivacyPage from "./PrivacyPage";

const ContactPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleInstagramRedirect = () => {
    window.open(
      "https://www.instagram.com/adswalaa3?igsh=MW1pMzNraHVtMHhycw==",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handlefacebookRedirect = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=61569300785398&mibextid=ZbWKwL",
      "_blank",
      "noopener,noreferrer"
    );
  };

  

  return (
    <>
      <footer className="py-10 px-5 md:px-20 bg-gradient-to-r from-purple-950 to-blue-900 text-white">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-10 md:mb-0 ">
            <div className="flex justify-center flex-row">
              {" "}
              <div>
                {" "}
                <Link to="/">
                  {" "}
                  <img src={logo} alt="Company Logo" className="mb-4" />
                </Link>
              </div>
            </div>
            <p className="poppins-medium mb-4">
              Break Free From Ad Restrictions
            </p>
            <div className="flex space-x-4">
              <i
                onClick={handlefacebookRedirect}
                className="fab fa-facebook fa-lg"
              ></i>

              <i
                onClick={handleInstagramRedirect}
                className="fab fa-instagram fa-lg"
              ></i>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-10">
            <div>
              <Link to="/aboutus" className="poppins-medium mb-4">
                About Us
              </Link>
            </div>
            <div>
              <h3 className="poppins-medium mb-4">
                {" "}
                <Link to="/ContectMe" className="poppins-medium mb-4">
                  Contact Us
                </Link>
              </h3>
              {/* <ul> */}
              {/* <h className="mb-2">
                  <a href="#" className="text-gray-400">
                    Contact Us
                  </a>
                </h> */}
              {/* </ul> */}
            </div>
            <div>
              <Link to="/new" className="poppins-medium mb-4">
                {" "}
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link to={"/Terms"} className="poppins-medium mb-4">
                {" "}
                Terms & Conditions
              </Link>
              {/* <ul>
                <li className="mb-2">
                  <a href="#" className="text-gray-400">
                    Private Policy
                  </a>
                </li>
                
              </ul> */}
            </div>
          </div>
        </div>
      </footer>

      {/* Menu Button with Transition */}
      <button
        className={`flex flex-col justify-between items-center w-8 h-6 bg-transparent border-none cursor-pointer p-0 transition-all duration-300 ease-in-out ${
          menuOpen ? "open" : ""
        }`}
        onClick={toggleMenu}
      >
        <div
          className={`h-1 bg-white transition-transform duration-300 ease-in-out transform ${
            menuOpen ? "rotate-45 translate-x-1.5 translate-y-1.5" : ""
          }`}
        ></div>
        <div
          className={`h-1 bg-white transition-opacity duration-300 ease-in-out ${
            menuOpen ? "opacity-0" : "opacity-100"
          }`}
        ></div>
        <div
          className={`h-1 bg-white transition-transform duration-300 ease-in-out transform ${
            menuOpen ? "rotate--45 translate-x-1.5 translate-y-1.5" : ""
          }`}
        ></div>
      </button>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-white bg-opacity-80 flex justify-center items-center transition-opacity duration-300 ease-in-out ${
          menuOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <ul className="text-white text-xl">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#">About</a>
          </li>
          <li>
            <a href="#">Services</a>
          </li>
        </ul>
        <button
          className="absolute top-3 right-3 text-white text-4xl font-bold"
          onClick={toggleMenu}
        >
          &times;
        </button>
      </div>

      <div className="flex justify-center mx-5 my-6 sm:my-8 md:my-10 text-base text-center sm:text-lg md:text-xl">
        All Rights Reserved By AdsWalaa
      </div>
    </>
  );
};

export default ContactPage;
