import Meta from "../images/meta.png";
import Google from "../images/google.png";
import metaimage from "../images/metasymbol.png";

import googlesymbol from "../images/googlesymbol.png";

function Partner() {
  return (
    <>
      <div className="h-auto flex flex-col justify-center px-4">
        <div className="flex justify-center">
          <div className="h-14 w-72 bg-[#D6E6FF] text-center pt-4 rounded-[38px] mt-8">
            Our Partnership Platform
          </div>
        </div>

        {/* Title Section */}
        <div className="flex justify-center content-center pt-10 text-3xl sm:text-4xl md:text-5xl lg:text-7xl text-center font-semibold">
          <h1 className="text-xl  poppins-medium sm:text-4xl md:text-6xl lg:text-6xl">
            Connecting You With Leading Media Platforms
          </h1>
        </div>

        {/* Description Section */}
        <div className="flex justify-center py-5">
          <div className="  text-sm  sm:text-sm poppins-medium font-black text-center w-full sm:w-3/4 md:w-1/2">
            Working seamlessly with major social media platforms, we provide
            enterprise-tier solutions for businesses of all sizes. Wave goodbye
            to advertising limitations, restrictions, and campaigns that are not
            sustainable.
          </div>
        </div>

        {/* Partner Logos and Information */}
        <div className="flex flex-col sm:flex-row justify-evenly gap-6 sm:gap-8 mt-14 mb-28">
          {/* Meta Section */}
          <div className="relative h-auto sm:h-60 w-full sm:w-[30rem] border-2 border-gray-200 rounded-xl p-6 sm:p-6 overflow-hidden bg-white">
            <img
              className="h-[2.1rem] w-[7.68rem]"
              src={Meta}
              alt="Meta Logo"
            />
            <h2 className="pl-3 mt-4 font-semibold text-sm text-left sm:text-left ">
              Meta Agency Accounts
            </h2>
            <p className="pl-3 text-left text-xs poppins-light sm:text-left mt-2">
              Unleash the complete power of your brand through Meta Advertising,
              connecting with billions of users on platforms like Facebook,
              Instagram, WhatsApp, and beyond.
            </p>
            {/* Overlay Image */}
            <img
              className="absolute -right-36 -bottom-16 w-[100%] h-[100%] object-none opacity-20 bg-white"
              src={metaimage}
              alt="Meta Image"
            />
          </div>

          {/* Google Section */}
          <div className="relative h-auto sm:h-60 w-full sm:w-[30rem] border-2 border-gray-200 rounded-xl p-4 sm:p-6 overflow-hidden bg-white">
            <img
              className="h-[4.1rem] w-[6.68rem] pl-3 sm:mx-0"
              src={Google}
              alt="Google Logo"
            />
            <h2 className="pl-3 font-semibold text-sm text-left sm:text-left mt-1">
              Google Agency Accounts
            </h2>
            <p className="pl-3 text-left text-xs poppins-light sm:text-left mt-2">
              Unlock the potential of Google's extensive network and boost your
              brand's online visibility with our specialized advertising
              campaigns, strategically crafted to engage your target audience
              effectively.
            </p>
            {/* Overlay Image */}
            <img
              className="absolute -right-8 -bottom-9 w-[50%] h-[50%]object-contain opacity-20"
              src={googlesymbol}
              alt="Google Symbol"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Partner;
