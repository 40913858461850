// App.js
import React from 'react';
import { Link } from 'react-scroll'; // For scroll effects
import { useEffect } from 'react';
// import { CSSTransition } from 'react-transition-group'; // Optional for advanced effects
// import { FaFacebook } from "react-icons/fa";
import facebook from '../images/facebook.png'
import ticktock from '../images/ticktock.png'
import drive from '../images/drive.png'
import snap from '../images/snap.png'

const Card = ({ bgColor, imgSrc, imgAlt, title, scrollTo }) => {
    return (
        <div className={`flex flex-col  items-center justify-center p-6 rounded-lg ${bgColor} w-full sm:flex-col sm:w-2/6 lg:w-1/4 m-2`}>
            <img src={imgSrc} alt={imgAlt} className="w-16 bg-white rounded-[38px] h-16 mb-4" />
            <h2 className="text-lg font-semibold text-center mb-2">{title}</h2>
            {/* <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md">
                <Link
                    to={scrollTo} // Scroll to a specific component when clicked
                    smooth={true}
                    duration={500}
                    className="cursor-pointer"
                >
                    <i className="fas fa-plus text-red-500"></i>
                </Link>
            </div> */}
        </div>
    );
};

const Agency = () => {
    useEffect(() => {
        // You can implement additional scroll-related JS here if needed
    }, []);

    return (
        <div className="flex flex-wrap justify-center p-4">
            <Card 
                bgColor="bg-yellow-100" 
                imgSrc={facebook}
                imgAlt="Facebook logo" 
                title="Rent Facebook Agency Account" 
                scrollTo="facebook-section" // Scroll target
            />
            <Card 
                bgColor="bg-purple-100" 
                imgSrc={ticktock}
                imgAlt="Tiktok logo" 
                title="Rent Tiktok Agency Account" 
                scrollTo="tiktok-section" // Scroll target
            />
            <Card 
                bgColor="bg-blue-100" 
                imgSrc={drive}
                imgAlt="Google logo" 
                title="Rent Google Agency Account" 
                scrollTo="google-section" // Scroll target
            />
            <Card 
                bgColor="bg-blue-100" 
                imgSrc={snap}
                imgAlt="Snapchat logo" 
                title="Rent Snapchat Agency Account" 
                scrollTo="snapchat-section" // Scroll target
            />

        </div>
    );
};

export default Agency;
