import React, { useState } from "react";
import { Element } from "react-scroll";

function Price() {
  const breakpoints = [500, 1000, 1500, 2000]; // Custom breakpoints
  const minlimit = 500;
  const [adSpend, setAdSpend] = useState(minlimit);
  const [plan, setPlan] = useState({ price: 90, description: "Plan" });

  // const breakpoint = [500, 1000, 2500, 4000]; // Custom breakpoints

  const handleSliderChange = (event) => {
    const inputValue = Number(event.target.value);
    const limitedValue = Math.max(inputValue, minlimit);

    const closestBreakpoint = breakpoints.reduce((prev, curr) =>
      Math.abs(curr - limitedValue) < Math.abs(prev - limitedValue)
        ? curr
        : prev
    );

    setAdSpend(closestBreakpoint);

    // Update the plan based on the adSpend
    if (closestBreakpoint <= 500) {
      setPlan({ price: 90, description: "Plan" });
    } else if (closestBreakpoint <= 1000) {
      setPlan({ price: 140, description: "Plan" });
    } else if (closestBreakpoint <= 1500) {
      setPlan({ price: 260, description: "Plan" });
    } else {
      setPlan({ price: 390, description: "Plan" });
    }
  };

  const handleEnquiryRedirect = () => {
    window.open("https://wa.me/916354231531", "_blank", "noopener,noreferrer");
  };

  const maxValue = 2000;
  const getSliderBackground = () => {
    const percentage = ((adSpend - minlimit) / (maxValue - minlimit)) * 100;
    return `linear-gradient(to right, #3b82f6 ${percentage}%, #ddd ${percentage}%)`;
  };

  return (
    <>
      <div id="pricePage">
        <Element
          name="firstInsideContainer"
          className="flex flex-col items-center mx-4 py-2 md:mx-8 lg:mx-16 overflow-x-hidden justify-center h-[32rem] bg-gradient-to-r from-blue-100 via-pink-100 to-yellow-100 px-4"
        >
          <h1 className="text-lg md:text-2xl font-semibold mb-2 text-center">
            Pick the right plan for your ad budget
          </h1>
          <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center">
            My Top-Up is up to{" "}
            <a href="#" className="text-blue-600">
              ${adSpend}
            </a>
          </h2>

          <div className="w-full max-w-xl mb-8">
            <div className="relative w-full">
              <input
                type="range"
                min={minlimit}
                max={maxValue}
                value={adSpend}
                onChange={handleSliderChange}
                style={{ background: getSliderBackground() }}
                className="slider appearance-none w-full h-2 rounded-full"
              />
              {/* Breakpoint Dots */}
              <div className="absolute top-1/2 transform -translate-y-1/2 w-full ml-3 mr-0">
                {breakpoints.map((breakpoint, index) => {
                  const positionPercentage =
                    ((breakpoint - minlimit) / (maxValue - minlimit)) * 99;
                  return (
                    <div
                      key={index}
                      className="w-1 h-1 bg-[#8c8e99] rounded-full absolute"
                      style={{
                        left: `calc(${positionPercentage}% - 5px)`, // Center-align the dot
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Plan Display */}
          <div className="flex flex-col md:flex-row gap-6 justify-center w-full">
            <div className="bg-blue-100 relative p-6 rounded-lg border mx-8 border-[#4C62ED] md:w-1/3">
              <div className="absolute -top-5 -right-5 w-10 h-10 rounded-full flex items-center justify-center text-[#4C62ED]">
                <i className="fas fa-circle-check"></i>
              </div>

              <h3 className="text-lg md:text-xl font-semibold mb-2 text-left">
                {plan.description}
              </h3>
              <p className="text-3xl md:text-4xl font-bold mb-2 text-left">
                {typeof plan.price === "number" ? `$${plan.price}` : plan.price}
                {typeof plan.price === "number" && (
                  <span className="text-lg font-normal">/Top-Up</span>
                )}
              </p>
              <div className="py-3">
                <button
                  onClick={handleEnquiryRedirect}
                  className="bg-blue-600 text-white py-3 font-bold px-4 rounded-md w-full"
                >
                  {typeof plan.price === "number"
                    ? "Send Enquiry"
                    : "Talk to us"}
                </button>
              </div>
            </div>
          </div>
        </Element>

        <style jsx>{`
          .slider {
            -webkit-appearance: none;
            appearance: none;
            width: 103%;
            height: 10px;
            border-radius: 9999px;
            background: #ddd;
            outline: none;
            transition: background 0.3s ease;
          }

          .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: yellow;
            border: 2px solid #fbbf24;
            cursor: pointer;
            transition: transform 1s ease, box-shadow 0.3s ease;
          }

          .slider::-moz-range-thumb {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: yellow;
            border: 2px solid #fbbf24;
            cursor: pointer;
          }
        `}</style>
      </div>
    </>
  );
}

export default Price;
