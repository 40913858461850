import "../Components/style.css";

function Table() {
  return (
    <>
      <div className="flex flex-col justify-center content-center my-8 px-4">
        <div className="flex justify-center flex-wrap content-center text-2xl poppins-medium text-center sm:text-4xl">
          <h>Comprehensive Support for Diverse Businesses</h>
        </div>
        <div className="h-18 flex justify-center py-5">
          <div className="flex justify-center poppins-bold text-sm text-center w-full sm:w-3/4 md:w-1/2">
            Tailored to fit businesses of every type and size, our expert teams
            offer bespoke solutions, empowering growth and success in the
            digital realm.
          </div>
        </div>
      </div>

      <div className="min-h-min min-w-min flex flex-row justify-evenly  flex-wrap gap-4 px-4 md:flex-row md:flex-wrap md:px-16 lg:px-16">
        {/* Brands */}

        <div class="flex  w-[9.3rem] md:mr-12 h-[3.1rem]  rounded-xl md:w-56 bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular text-sm bg-white  rounded-xl">
            Brands
          </button>
        </div>
        <div class="flex  w-[9.3rem]  md:mr-12 h-[3.1rem]  rounded-xl md:w-56 bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular text-sm bg-white  rounded-xl">
            Brands
          </button>
        </div>

        {/* Marketing Agencies */}
        <div class="flex  w-[9.3rem]  md:mr-12  h-[3.1rem] md:w-56 rounded-xl bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular  pr-2 pl-2 text-sm bg-white  rounded-xl">
          Marketing Agencies
          </button>
        </div>
        {/* Local Business */}

        <div class="flex  min-w-minw-[9.3rem]  md:mr-12  h-[3.1rem]  md:w-56  rounded-xl bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular text-sm bg-white px-6 py-3 rounded-xl">
          Local Business
          </button>
        </div>

        {/* Freelancers */}

        <div class="flex  min-w-min-[9.3rem]  md:mr-12  h-[3.1rem] md:w-56  rounded-xl bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular text-sm bg-white px-6 py-3 rounded-xl">
          Freelancers
          </button>
        </div>

        {/* Affiliates */}

        <div class="flex min-w-min w-[9.3rem] h-[3.1rem]  md:mr-12 md:w-56 rounded-xl bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular text-sm bg-white px-6 py-3 rounded-xl">
          Affiliates
          </button>
        </div>

        {/* Dropshippers */}

        <div class="flex  min-w-min h-[3.1rem]  md:mr-12  rounded-xl md:w-56  bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular text-sm  bg-white px-6 py-3 rounded-xl">
          Dropshippers
          </button>
        </div>
        {/* Influencers */}

        <div class="flex  w-[9.3rem] h-[3.1rem]  md:mr-12  rounded-xl md:w-56 bg-gradient-to-tr from-blue-400 to-pink-700 p-0.5 shadow-lg">
          <button class="flex-1 poppins-regular text-sm bg-white px-6 py-3 rounded-xl">
          Influencers
          </button>
        </div>
      </div>
    </>
  );
}

export default Table;
