import React from "react";
import avtar from "../images/avtar.png";
function Owner() {
  return (
    <>
      <div className="flex flex-col  mt-16 min-h-min pb-7 mb-0 px-4 md:h-[23rem] ">
        {/* Title Section */}
        <div className="flex justify-center mb-16">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-center">
            This is why customers love us
          </h1>
        </div>

        {/* Testimonials Section with Animation */}
        <div className="flex flex-nowrap overflow-hidden">
          <div className="flex flex-row  animate-scroll-left gap-6 sm:gap-8">
            {/* Testimonial 1 */}
            <div className="testimonial-item pb-0  h-[15rem] w-[25rem] pr-[2.75rem] pt-[2.25rem] pl-[2.25rem] border-2 border-[#FAF5FF] bg-[#FAF5FF] md:w-[35rem] rounded-[38px] flex flex-col">
              <div className="flex items-center flex-row space-x-4">
                <img
                  src={avtar}
                  alt="Kameshwaran Elangovan Avatar"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className=" flex flex-col ml-5">
                  <div className="font-bold text-xl">Moin ChandiWala</div>
                  <div>Founder & CEO, AdsWalaa</div>
                </div>
              </div>
              <div className="mt-4 pb-5 text-xs  poppins-medium">
                AdsWalaa expert consultations have been crucial. They helped us
                align with Meta and Google policies, ensuring smooth ad
                campaigns.
              </div>
            </div>

            {/* Testimonial 2 */}
            <div className="testimonial-item  h-[15rem] w-[25rem] pr-[2.75rem] pt-[2.25rem] pl-[2.25rem] border-2 bg-[#FFF0F0] border-[#FFF0F0] md:w-[35rem] rounded-[38px] flex flex-col">
              <div className="flex items-center flex-row space-x-4">
                <img
                  src={avtar}
                  alt="Kameshwaran Elangovan Avatar"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className=" flex flex-col ml-5">
                  <div className="font-bold text-xl">Adil ChandiWala</div>
                  <div>Co-Founder, AdsWalaa</div>
                </div>
              </div>
              <div className="mt-4 pb-5 text-xs  poppins-medium">
              AdsWalaa expert consultations have been crucial. They helped us
                align with Meta and Google policies, ensuring smooth ad
                campaigns.
              </div>
            </div>

            {/* Testimonial 3 */}
            <div className="testimonial-item  h-[15rem] w-[25rem] pr-[2.75rem] pt-[2.25rem] pl-[2.25rem] border-2 border-[#F0FAFF] bg-[#F0FAFF] md:w-[35rem] rounded-[38px] flex flex-col">
              <div className="flex items-center flex-row space-x-4">
                <img
                  src={avtar}
                  alt="Kameshwaran Elangovan Avatar"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className=" flex flex-col ml-5">
                  <div className="font-bold text-xl">Ibhraim BhangdiWala</div>
                  <div>Co-Founder, AdsWalaa</div>
                </div>
              </div>
              <div className="mt-4 pb-5 text-xs  poppins-medium">
              AdsWalaa expert consultations have been crucial. They helped us
                align with Meta and Google policies, ensuring smooth ad
                campaigns.
              </div>
            </div>

            <div className="testimonial-item h-[15rem] w-[25rem] pr-[2.75rem] pt-[2.25rem] pl-[2.25rem] border-2 border-[#F0FAFF] bg-[#F0FAFF] md:w-[35rem] rounded-[38px] flex flex-col">
              <div className="flex items-center flex-row space-x-4">
                <img
                  src={avtar}
                  alt="Kameshwaran Elangovan Avatar"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className=" flex flex-col ml-5">
                  <div className="font-bold text-xl">Moin ChandiWala</div>
                  <div>Founder & CEO, AdsWalaa</div>
                </div>
              </div>
              <div className="mt-4 pb-5 text-xs  poppins-medium">
              AdsWalaa expert consultations have been crucial. They helped us
                align with Meta and Google policies, ensuring smooth ad
                campaigns.
              </div>
            </div>

            <div className="testimonial-item h-[15rem] w-[25rem] pr-[2.75rem] pt-[2.25rem] pl-[2.25rem] border-2 border-[#FFF0F0] bg-[#FFF0F0] md:w-[35rem] rounded-[38px] flex flex-col">
              <div className="flex items-center flex-row space-x-4">
                <img
                  src={avtar}
                  alt="Kameshwaran Elangovan Avatar"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className=" flex flex-col ml-5">
                  <div className="font-bold text-xl">Adil ChandiWala</div>
                  <div>Co-Founder, AdsWalaa</div>
                </div>
              </div>
              <div className="mt-4 pb-5 text-xs  poppins-medium">
              AdsWalaa expert consultations have been crucial. They helped us
                align with Meta and Google policies, ensuring smooth ad
                campaigns.
              </div>
            </div>

            <div className="testimonial-item h-[15rem] w-[25rem] pr-[2.75rem] pt-[2.25rem] pl-[2.25rem] border-2 border-[#F0FAFF] bg-[#F0FAFF] md:w-[35rem] rounded-[38px] flex flex-col">
              <div className="flex items-center flex-row space-x-4">
                <img
                  src={avtar}
                  alt="Kameshwaran Elangovan Avatar"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className=" flex flex-col ml-5">
                  <div className="font-bold text-xl">Ibhraim BhangdiWala</div>
                  <div>Co-Founder, AdsWalaa</div>
                </div>
              </div>
              <div className="mt-4 pb-5text-xs  poppins-medium">
              AdsWalaa expert consultations have been crucial. They helped us
                align with Meta and Google policies, ensuring smooth ad
                campaigns.
              </div>
            </div>

            <div className="testimonial-item h-[15rem] w-[25rem] pr-[2.75rem] pt-[2.25rem] pl-[2.25rem] border-2 border-[#F0FAFF] bg-[#F0FAFF] md:w-[35rem] rounded-[38px] flex flex-col">
              {/* Avatar and Name */}
              <div className="flex items-center flex-row space-x-4">
                <img
                  src={avtar}
                  alt="Kameshwaran Elangovan Avatar"
                  className="h-10 w-10 rounded-full object-cover"
                />
                <div className=" flex flex-col ml-5">
                  <div className="font-bold text-xl">Moin ChandiWala</div>
                  <div>Founder & CEO, AdsWalaa</div>
                </div>
              </div>

              <div className="mt-4 pb-5 text-xs poppins-medium">
              AdsWalaa expert consultations have been crucial. They helped us
                align with Meta and Google policies, ensuring smooth ad
                campaigns.
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        /* Keyframe for scrolling the testimonials to the left with easing */
        @keyframes scroll-left {
          0% {
            transform: translateX(0%); /* Start off-screen to the right */
          }
          100% {
            transform: translateX(-100%); /* Move off-screen to the left */
          }
        }

        /* Apply the scroll-left animation */
        .animate-scroll-left {
          animation: scroll-left 45s ease-in-out infinite;
        }

        /* Hover Effect: Pause animation and add scale effect */
        .testimonial-item:hover {
          animation-play-state: paused; /* Pause animation on hover */
          // transform: scale(1.05); /* Slightly scale up the testimonial */
        }

        /* Ensure animation runs when not hovering */
        .animate-scroll-left {
          animation-play-state: running; /* Ensure animation runs by default */
        }
      `}</style>
    </>
  );
}

export default Owner;

// import image1 from "../images/1.png";
// import image2 from "../images/2.png";
// import image3 from "../images/3.png";
// import image4 from "../images/4.png";
// import image5 from "../images/5.png";
// import '../Components/style.css';
// function List() {
//   return (
//     <>
//       {/* Header Section */}
//       <div className="flex justify-center min-h-min mx-2 py-4 text-4xl text-center my-4 font-bold">
//         Why Choose ADWALA?
//       </div>

//       {/* Main Content */}
//       <div className="flex flex-col md:flex-row">
//         {/* Left Column (Image and Text Column) */}
//         <div className=" md:w-full px-4">
//           {/* Item 1 */}
//           <div className="flex flex-col md:flex-row items-center  border-2  border-[#DFB6FF]  rounded-[38px] bg-[#FAF5FF] md:bg-[#ffffff] mx-3 my-3  min-h-[590px]">
//             {/* Image */}
//             <div className="flex px-10 pt-10 justify-center items-center  rounded-[38px] min-h-[332px]   w-full md:w-2/4  bg-[#FAF5FF] mx-4">
//               <img
//                 src={image1}
//                 alt="Image 1"
//                 className="object-contain max-w-full h-auto"
//               />
//             </div>
//             {/* Text */}
//             <div className="flex flex-col justify-center items-center w-full  md:w-1/2 px-8 pb-4">
//               <div className=" text-2xl  mb-4 md:text-left text-center font-bold ">
//                 Instant Account Top-Up
//               </div>
//               <div className=" text-sm poppins-regular text-center poppins-medium">
//                 Our automated top-up system is a game-changer compared to the
//                 manual, time-consuming process used by our competitors. With
//                 Ad360, your account is recharged in seconds, ensuring continuous
//                 ad campaign momentum.
//               </div>
//             </div>
//           </div>

//           {/* Item 2 */}
//           <div className="flex flex-col md:flex-row items-center border-2   border-[#E0C36A] rounded-[38px]  bg-[#FFFAEB] md:bg-white mx-3 my-3  min-h-[590px]">
//             {/* Image */}
//             <div  className="flex justify-center items-center px-10 pt-6  rounded-[38px] min-h-[332px] w-full md:w-2/4  bg-[#FFFAEB] mx-4">
//               <img
//                 src={image2}
//                 alt="Image 2"
//                 className="object-contain max-w-full h-auto"
//               />
//             </div>
//             {/* Text */}
//             <div className="flex flex-col justify-center items-center w-full md:w-1/2 px-8 pb-6">
//               <div className="text-center text-2xl font-bold mb-4">
//                 Advanced Analytics Dashboard
//               </div>
//               <div className="text-sm poppins-regular text-center poppins-medium">
//                 While others lack efficient monitoring tools, Ad360 provides a
//                 comprehensive analytics dashboard. Gain valuable insights into
//                 your Meta and Google ads easily, making data-driven decisions
//                 without the complexity.
//               </div>
//             </div>
//           </div>

//           {/* Item 3 */}
//           <div className="flex flex-col md:flex-row items-center border-2 border-[#9ADDFF] rounded-[38px] bg-[#F0FAFF] md:bg-white mx-3 my-3  min-h-[590px]">
//             {/* Image */}
//             <div  className="flex justify-center  px-10 pt-8 items-center min-h-[332px] bg-[#F0FAFF] rounded-[38px] w-full md:w-2/4  mx-4">
//               <img
//                 src={image3}
//                 alt="Image 3"
//                 className="object-contain max-w-full h-auto"
//               />
//             </div>
//             {/* Text */}
//             <div className="flex flex-col justify-center items-center w-full md:w-1/2 px-8 pb-6">
//               <div className="text-center text-2xl font-bold mb-4">
//                 Seamless Integration
//               </div>
//               <div className="text-sm poppins-regular text-center poppins-medium">
//               Our automated top-up system is a game-changer compared to the
//                 manual, time-consuming process used by our competitors. With
//                 Ad360, your account is recharged in seconds, ensuring continuous
//                 ad campaign momentum.
//               </div>
//             </div>
//           </div>

//           {/* Item 4 */}
//           <div className="flex flex-col md:flex-row items-center  border-2  border-[#FF9A9A] rounded-[38px]  bg-[#FFF0F0] md:bg-white mx-3 my-3 min-h-[590px]">
//             {/* Image */}
//             <div  className="flex justify-center px-10 pt-8 items-center min-h-[332px]  bg-[#FFF0F0] rounded-[38px]   w-full md:w-2/4 mx-4">
//               <img
//                 src={image4}
//                 alt="Image 4"
//                 className="object-contain max-w-full h-auto"
//               />
//             </div>
//             {/* Text */}
//             <div className="flex flex-col justify-center items-center w-full md:w-1/2 px-8 pb-6">
//               <div className="text-center text-2xl font-bold mb-4">
//                 Professional Support
//               </div>
//               <div className="text-sm poppins-regular text-center poppins-medium">
//               Our automated top-up system is a game-changer compared to the
//                 manual, time-consuming process used by our competitors. With
//                 Ad360, your account is recharged in seconds, ensuring continuous
//                 ad campaign momentum.
//               </div>
//             </div>
//           </div>

//           {/* Item 5 */}
//           <div className="flex flex-col md:flex-row items-center rounded-[38px] border-2 border-[#9ADDFF]  bg-[#F0FAFF]  md:bg-white mx-3 my-3  min-h-[590px]">
//             {/* Image */}
//             <div  className="flex justify-center px-10 pt-8 items-center  min-h-[332px] rounded-[38px] bg-[#F0FAFF] w-full md:w-2/4 mx-4">
//               <img
//                 src={image5}
//                 alt="Image 5"
//                 className="object-contain max-w-full h-auto"
//               />
//             </div>
//             {/* Text */}
//             <div className="flex flex-col justify-center items-center w-full md:w-1/2 px-8 pb-6">
//               <div className="text-center text-2xl font-bold mb-4">
//                 Recognized Partner
//               </div>
//               <div className="text-sm poppins-regular text-center poppins-medium">
//               Our automated top-up system is a game-changer compared to the
//                 manual, time-consuming process used by our competitors. With
//                 Ad360, your account is recharged in seconds, ensuring continuous
//                 ad campaign momentum.
//               </div>
//             </div>
//           </div>
//         </div>

//       </div>
//     </>
//   );
// }

// export default List;
