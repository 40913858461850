import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Components/Main";
import PrivacyNew from "./Components/PrivacyNew";
import Terms from "./Components/Terms";
// import ContactPage from "./Components/ContactPage";
import Price from "./Components/Price";
import About from "./Components/About";
import HelloPage from "./Components/HelloPage";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />

          <Route path="/new" element={<PrivacyNew />} />
          <Route path="/Terms" element={<Terms />} />

          <Route path="/aboutus" element={<About />} />
          <Route path="/home" element={<Main />} />
          <Route path="/ContectMe" element={<HelloPage />} />




        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
