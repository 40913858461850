// import { Button } from "@mui/material";
import myImage from "../images/Product.jpg";
// import "../Component/navbar.css";
import '../Components/style.css'
import whatsap from '../images/whatsap.png'
export default function Body() {

  const handleWhatsap = () => {
    window.open('https://wa.me/916354231531', '_blank', 'noopener,noreferrer');
  };

  const handleEnquiryRedirect = () => {
    window.open(
      "https://wa.me/916354231531",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <>
      <div id="homePage">
      <div className="flex flex-col lg:flex-row items-center lg:items-start">
        {/* Left Section (Text and Button) */}
        <div className="lg:w-1/2 px-14 sm:px-6 lg:px-16 text-center lg:text-left">
          <div className="relative overflow-hidden bg-white">
            <div className="pb-0 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
              <div className="relative mx-auto max-w-7xl">
                <div className="sm:max-w-lg">
                  <h1 className=" poppins-medium  text-3xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 sm:text-6xl">
                    Break Free From Ad Restrictions
                  </h1>
                  <p className=" poppins-bold text-black mt-4  text-base ">
                    Resume Meta and Google ad campaigns effortlessly. No more ad
                    account worries. Get started within seconds Book a Demo
                  </p>
                </div>

                <div className="flex justify-center md:justify-start items-center mt-10">
                  <div  onClick={handleEnquiryRedirect} className="font-bold text-xl h-14 w-56 bg-[#334cf0] text-white  py-3 rounded-full cursor-pointer text-center">
                    Send Enquiry
                  </div>
                </div>

                <div className="mt-10">
                  {/* <div className="action">
                    <Button variant="contained" color="primary">
                      Send Enquiry
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section (Image) */}
        <div className="lg:w-[38rem] lg:h-[42rem] w-[23rem] pl-1 h-[23rem] mt-8 lg:px-0 lg:mt-0">
          <img
            src={myImage}
            alt="Summer Collection"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>

      </div>
      <div onClick={handleWhatsap} className="flex justify-end fixed right-5 bottom-9 w-16 z-10 bg-white rounded-[38px] h-16 mb-4 cursor-pointer"><img src={whatsap}></img></div>
    </>
  );
}
