import { Link } from "react-router-dom";

import List from "./List";
import Owner from "./Owner";
import Partner from "./Partner";
import Table from "./Table";
import ContactPage from "./ContactPage";
import Body from "./Body";
import Navbar from "./Navbar";
import Price from "./Price";
import Dashboard from "./Dashboard";
import Agency from "./Agency";

function Main(){
    return(
        <>
        <Link to='/' >
        <Navbar/>
          <Body></Body>
          <Agency></Agency>
          <Dashboard></Dashboard>
          <List></List>
          <Table></Table>
          <Partner></Partner>
          <Price></Price>
          <Owner></Owner>
          <ContactPage></ContactPage>
        </Link>
     
        </>
    )
}
export default Main;