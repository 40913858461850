import { useState } from "react";
import gd from "../images/new.png";
import { Link as ScrollLink } from "react-scroll"; // Importing react-scroll Link
import "../Components/style.css";
import { Link } from "react-router-dom";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track the menu visibility

  // Toggle the menu visibility
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Close the menu when Price is clicked and scroll to the Price section
  const handlePriceClick = () => {
    setIsMenuOpen(false); // Close the menu
  };

  const handleHomeClick = () => {
    setIsMenuOpen(false); // Close the menu
  };

  const handleEnquiryRedirect = () => {
    window.open("https://wa.me/916354231531", "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div className="navbar sticky top-0 z-50 bg-white flex justify-between items-center px-0 py-2 shadow-md">
        {/* Logo Section (for larger screens) */}
        <div className="logo h-7 w-40 hidden md:block">
          <Link to="/">
            {" "}
            <img src={gd} alt="Logo" className="h-full w-full object-contain" />
          </Link>
        </div>

        {/* Navigation Links for Desktop */}
        <div className="nav-links hidden md:flex space-x-12">
          <div className="cursor-pointer">
            {" "}
            <Link to={"/home"}>Home </Link>
          </div>
          <div className="cursor-pointer">
            {" "}
            <Link to={"/aboutus"}>About </Link>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => window.scrollTo({ top: 4200, behavior: "smooth" })}
          >
            {" "}
            Price
          </div>
          <div className="cursor-pointer">FAQ</div>
          <div className="cursor-pointer">Blogs</div>
        </div>

        {/* Action Button for Desktop */}
        <div
          onClick={handleEnquiryRedirect}
          className="action hidden md:flex bg-[#334cf0]  text-white px-6 py-2 mr-10 rounded-full cursor-pointer"
        >
          Send Enquiry
        </div>

        {/* Mobile View */}
        <div className="flex md:hidden w-full justify-between items-center">
          {/* Menu Icon (Left Aligned) */}
          <div
            className="menu-icon px-1 py-1 flex cursor-pointer ml-4 border-2 max-h-min max-w-max bg-[#149EFC] rounded-[38px]"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              // Close (X) icon: Appears when the menu is open
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                stroke="white" // Ensure the stroke is white
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              // Hamburger (three lines) icon: Appears when the menu is closed
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                stroke="white" // Ensure the stroke is white
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </div>

          {/* Logo in the center for mobile */}
          <div className="flex justify-center">
            <div className="logo h-7 w-40 mr-28">
              <Link to="/">
                {" "}
                <img
                  src={gd}
                  alt="Logo"
                  className="h-full w-full object-contain"
                />
              </Link>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`mobile-menu  md:hidden h-screen absolute top-14 left-0 w-full bg-white shadow-lg pl-6 pt-8  transition-all duration-500 ease-in-out ${
            isMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <div  onClick={handleHomeClick} className="cursor-pointer text-xl poppins-medium py-2">
            {/* <Link to={"/home"}>Home </Link> */}
            <div className="pt-3">
            <ScrollLink
              to="homePage" // Ensure this matches the ID of your Price component
              smooth={true}
              duration={1500}
              offset={-70} // Adjust for sticky header height
              className="cursor-pointer text-xl poppins-medium py-2"
              onClick={handleHomeClick} // Close the menu when clicked
            >
              Home
            </ScrollLink>
          </div>
          </div>
          <div className="cursor-pointer text-xl poppins-medium py-2">
            <Link to={"/aboutus"}>About </Link>
          </div>

          {/* ScrollLink for smooth scroll to Price section */}
          <div className="pt-3">
            <ScrollLink
              to="pricePage" // Ensure this matches the ID of your Price component
              smooth={true}
              duration={1500}
              offset={-70} // Adjust for sticky header height
              className="cursor-pointer text-xl poppins-medium py-2"
              onClick={handlePriceClick} // Close the menu when clicked
            >
              Price
            </ScrollLink>
          </div>

          <div
            onClick={handleEnquiryRedirect}
            className="cursor-pointer poppins-medium py-4 bg-gradient-to-r from-[#334cf0] to-blue-500 w-150 mt-5 text-white pl-6 rounded-full"
          >
            Send Enquiry
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
