import React from 'react';

function FeatureSection() {
  return (
    <div className="bg-gradient-to-r from-green-900 to-blue-900 rounded-lg">
      <div className="flex justify-center items-center py-8 px-4 sm:px-8 md:px-12">
        <div className="flex flex-col md:flex-row items-start md:justify-evenly space-y-4 md:space-y-0 md:space-x-8 text-white w-full max-w-7xl">
          
          {/* Feature 1 */}
          <div className="flex justify-start items-center space-x-2 ml-4">
            <i className="fas fa-check-circle text-green-500 text-2xl"></i>
            <div>
              <p className="font-bold text-lg sm:text-base">No limits in daily ad spends from Day 1</p>
              <p className="text-sm sm:text-base">Start with a bang</p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex justify-start items-center space-x-2 ml-4">
            <i className="fas fa-check-circle text-green-500 text-2xl"></i>
            <div>
              <p className="font-bold text-lg sm:text-base">24*7 ad account top up</p>
              <p className="text-sm sm:text-base">No more waiting for days</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
