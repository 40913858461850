import { useEffect } from "react";

import image1 from "../images/1.png";
import image2 from "../images/2.png";
import image3 from "../images/3.png";
import image4 from "../images/4.png";
import image5 from "../images/5.png";
import "../Components/style.css";
import AOS from "aos";
import "aos/dist/aos.css";

function List() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      {/* Header Section */}
      <div className="flex justify-center min-h-min mx-2 py-4 text-4xl text-center my-4 font-bold">
        Why to Choose ADSWALAA?
      </div>

      {/* Main Content */}
      <div className="flex flex-col md:justify-between md:flex-row">
        {/* Left Column (Image and Text Column) */}
        <div className=" md:w-full px-4 ">
          {/* Item 1 */}
          <div className="flex flex-col md:flex-row md:justify-between items-center  border-2  md:border-white border-[#DFB6FF]  rounded-[38px] bg-[#FAF5FF] md:bg-[#ffffff] mx-3 my-3  min-h-[590px]">
            {/* Image */}
            <div className="flex px-10 pt-6 justify-center  items-center  rounded-[38px] min-h-[332px] md:border-2 md:border-[#DFB6FF]  w-full md:w-2/5  bg-[#FAF5FF] mx-4">
              <img
                data-aos="fade-up"
                src={image1}
                alt="Image 1"
                className="object-contain max-w-full h-auto"
              />
            </div>
            <div>
              <div className="hidden md:flex justify-center items-center relative border-[1px] border-gray-300 rounded-xl  ">
                {/* Vertical Line */}
                <div className=" h-96 -left-2  bg-pink-600"></div>
                {/* Circular Indicator */}
                <div className="absolute -translate-x-1/2  left-0 -translate-y-1/2 bg-[#cca0ee] text-[#aa6ee6] rounded-full w-12 h-12   flex justify-center items-center text-lg font-bold">
                  1
                </div>
              </div>
            </div>

            {/* Text */}
            <div
              data-aos="zoom-in-up"
              className="flex flex-col justify-end items-center w-full  md:w-2/5 px-8 pb-6  md:pr-16"
            >
              <div className=" text-sm md:text-left poppins-regular text-left poppins-medium">
                <p>
                ✅ No account banning
                <br></br>
                <br></br>
                ✅ No commission on top up
                <br></br>
                <br></br>
                ✅ No spending limit(unlimited spending)
                <br></br>
                <br></br>
                ✅ No 18% GST
                <br></br>✅ No monthly charges
                </p>
              </div>
            </div>
          </div>

          {/* Item 2 */}
          <div className="flex flex-col md:flex-row md:justify-between items-center border-2   md:border-white border-[#E0C36A] rounded-[38px]  bg-[#FFFAEB] md:bg-white mx-3 my-3  min-h-[590px]">
            {/* Image */}
            <div className="flex justify-center items-center px-10 pt-6  rounded-[38px] min-h-[332px] w-full md:w-2/5 md:border-2 md:border-[#E0C36A] bg-[#FFFAEB] mx-4">
              <img
                data-aos="zoom-in-up"
                src={image2}
                alt="Image 2"
                className="object-contain max-w-full h-auto"
              />
            </div>
            <div>
              <div className="hidden md:flex justify-center items-center relative border-[1px] border-gray-300 rounded-xl  ">
                {/* Vertical Line */}
                <div className=" h-96 -left-2  bg-pink-600"></div>
                {/* Circular Indicator */}
                <div className="absolute -translate-x-1/2  left-0 -translate-y-1/2 bg-[#f1da95] text-[#d5b864] rounded-full w-12 h-12 flex justify-center items-center text-lg font-bold">
                  2
                </div>
              </div>
            </div>
            {/* Text */}
            <div
              data-aos="zoom-in-up"
              className="flex flex-col justify-center items-center w-full  md:w-2/5 px-8 pb-6  md:pr-16"
            >
              <div className="text-sm poppins-regular md:text-left poppins-regular text-left poppins-medium">
                <p>
                ✅ Top up will be charged as dollars not as USDT
                <br></br>
                <br></br>

                ✅ No extra charge on dollars
                <br></br>
                <br></br>

                ✅ When you top up we charge $1 as 85 INR , we do not charge $1
                as 91 INR or 95 INR like other agencies
                </p>
              </div>
            </div>
          </div>

          {/* Item 3 */}
          <div className="flex flex-col md:flex-row   md:justify-between items-center rounded-[38px] border-2 md:border-white border-[#9ADDFF]  bg-[#F0FAFF]  md:bg-white mx-3 my-3  min-h-[590px]">
            {/* Image */}
            <div className="flex justify-center px-10 pt-8 items-center min-h-[332px] md:border-2 md:border-[#9ADDFF] bg-[#F0FAFF] rounded-[38px] w-full md:w-2/5 mx-4">
              <img
                data-aos="zoom-in-up"
                src={image3}
                alt="Image 3"
                className="object-contain max-w-full h-auto"
              />
            </div>

            {/* Vertical Line with Circular Indicator */}
            <div>
              <div className="hidden md:flex justify-center items-center relative border-[1px] border-gray-300 rounded-xl  ">
                {/* Vertical Line */}
                <div className=" h-96 -left-2  bg-pink-600"></div>
                {/* Circular Indicator */}
                <div className="absolute -translate-x-1/2  left-0 -translate-y-1/2 bg-[#F0FAFF] text-[#79c6ec] rounded-full w-12 h-12 flex justify-center items-center text-lg font-bold">
                  3
                </div>
              </div>
            </div>

            {/* Text */}
            <div
              data-aos="zoom-in-up"
              className="flex flex-col justify-center items-center w-full md:w-2/5 px-8 pb-6 md:pr-16 "
            >
              <div className="text-sm poppins-regular md:text-left text-left poppins-medium">
               <p>
               ✅ Get a new ad account within 24 hours if your account gets
                banned and your remaining top up balance will also get
                transferred to a new account.
                <br></br>
                <br></br>
                <br></br>
                ✅ Note: our charges are on every top up and are not monthly
                subscriptions.
               </p>
              </div>
            </div>
          </div>

          {/* <div className="h-full w-2 border-5 border-pink-600"></div> */}

        

        </div>
      </div>
    </>
  );
}

export default List;
